import React from 'react';
import { SpeedInsights } from "@vercel/speed-insights/react"
import logoReact from '../src/misc/logoReact.svg';
import logoNest from '../src/misc/logoNest.svg';
import './App.css';

export const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <p className={`text-stone-500`}>
          Site em Desenvolvimento, utilizando as seguintes frameworks, em Typescript:
        </p>
        <div className={'grid grid-cols-2 gap-[100px]'}>
          <div className={`col-span-1 flex flex-col justify-center items-center`}>
            <img src={logoReact} className="App-logo-a" alt="logo" style={{width: 200}}/>
            <p className={`text-stone-500`}>
              React
            </p>
          </div>
          <div className={`col-span-1 flex flex-col justify-center items-center`}>
            <img src={logoNest} className="App-logo-b" alt="logo" style={{width: 120}} />
            <p className={`text-stone-500`}>
              Nest
            </p>
          </div>
          <SpeedInsights />
        </div>
      </header>
    </div>
  );
}